<template>
  <div class="paybills">
    <p class="path">Frequently Asked Questions > TayoCash Services > Pay Bills</p>
    <h3>Pay Bills</h3>
    <h4 id="pb1">Where can I see all TayoCash-accredited billers?</h4>
    <div class="paragraph">
      <p>You can see all the TayoCash billers when you go to the <span class="vtxt-align">Pay Bills</span> section at the home page of your app.</p>
    </div>

    <h4 id="pb2">How do I pay bills through TayoCash?</h4>
    <div class="paragraph">
      <ol>
        <li>Open your TayoCash app. Choose from among all the Billers listed in the <span class="vtxt-align">Pay Bills</span> section of the home page.</li>

        <li>Fill out the Bills Payment form with the necessary details and tap <span class="vtxt-align">Next</span>.</li>

        <li>A pop-up message will appear to validate your transaction. Press <span class="vtxt-align">Proceed</span>.</li>

        <li>Review the transaction details and click <span class="vtxt-align">Confirm</span>.</li>

        <li>A transaction receipt will appear in your TayoCash app for the confirmed bills payment transaction and the applicable convenience fee of the biller. Check your new wallet balance.</li>
      </ol>
    </div>

    <h4 id="pb3">I paid my bills via TayoCash, but my payment is not posted yet. What should I do?</h4>
    <div class="paragraph">
      <p>
        The standard bills payment posting takes up to 3 business days, subject to the processing time of the biller. Make sure to pay in advance before your due date to avoid inconvenience.
      </p>
    </div>

    <h4 id="pb4">I’m still experiencing the same problem or I have other concerns with the Bills Payment Service. What should I do?</h4>
    <div class="paragraph">
      <ol>
        <li>
          <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">Submit a ticket</router-link> via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website:
          <ul class="loweralpha">
            <li>Select <span class="vtxt-align">Bills Payment Transaction</span></li>
            <li>Input the following details in the text field:
              <ul>
                <li>Amount</li>
                <li>Biller Name</li>
                <li>Date and Time of Transaction</li>
                <li>Transaction Reference Number</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Send us an email at <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>:
          <ul class="loweralpha">
            <li>Email Subject: Bills Payment Issue</li>
            <li>Email Content:
              <ul>
                <li>Amount</li>
                <li>Biller Name</li>
                <li>Date and Time of Transaction</li>
                <li>Transaction Reference Number</li>
                <li>Transaction receipt</li>
                <li>Screenshot of the error message (If applicable)</li>
                <li>
                  <a class="link" href="https://assets.prod.tayocash.com/forms/transaction_dispute_form.pdf" download target="_blank">Transaction Dispute Form</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        A TayoCash Customer Care Representative will get back to you within 48 hours after you have submitted the ticket.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'paybills'
}
</script>

<style scoped lang='scss'></style>
